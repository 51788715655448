import Swiper from 'swiper';
// Import Swiper core and required modules
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

export default async function init() {
  const swipers = [...document.querySelectorAll('.js-swiper')];
  if (!swipers.length) return;
  // configure Swiper to use modules
  Swiper.use([Pagination, Navigation]);
  for (const element of swipers) {
    // Configure default options used by all swipers
    const options = {
      speed: 1000,
    };

    if (element.classList.contains('js-blog-post-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 35;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.breakpoints = {
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    } else if (element.classList.contains('js-completed-projects-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 35;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.breakpoints = {
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    } else if (element.classList.contains('js-featured-posts-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 35;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.breakpoints = {
        768: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    } else if (element.classList.contains('js-testimonials-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 30;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    } else if (element.classList.contains('js-fullwidth-image-carousel-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 0;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    } else if (element.classList.contains('js-product-notification-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = true;
      options.loop = true;
      options.spaceBetween = 35;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      const parentContainer = document.querySelector('.js-product-notification-tiles');
      const prevButton = parentContainer.querySelector('.swiper-button-prev');
      const nextButton = parentContainer.querySelector('.swiper-button-next');
      if (nextButton && prevButton) {
        nextButton.addEventListener('click', () => swiper.slideNext());
        prevButton.addEventListener('click', () => swiper.slidePrev());
      }
    } else if (element.classList.contains('js-cladding-what-to-expect-swiper')) {
      options.slidesPerView = 1.25;
      options.centeredSlides = true;
      options.loop = false;
      options.spaceBetween = 15;
      options.initialSlide = 0;
      options.pagination = {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      };
      options.breakpoints = {
        768: {
          slidesPerView: 3,
          centeredSlides: false,
          spaceBetween: 2,
        },
      };
      options.navigation = {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev'),
      };
    }

    const swiper = new Swiper(element, options);

    swiper.init();
  }
}
